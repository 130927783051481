<script>
export default {

}
</script>

<template>
  <CBox mx="3">
    <CText class="text-center" color="white">
      <CLink
          href="https://www.tradingview.com/economic-calendar/"
          isExternal
          rel="nofollow"
      >
        Charting is displayed using TradingView's technology, a platform, where you can track the latest events in the Economic calendar, watch live prices, and more
      </CLink>
    </CText>
  </CBox>
</template>

<style scoped>
</style>
