<template>
  <Analytics />
  <SpeedInsights />
  <sidebar-menu
      v-if="!isOnMobile"
      v-model:collapsed="collapsed"
      :menu="menu"
      :show-one-child="true"
      @update:collapsed="onToggleCollapse"
      @item-click="onItemClick"
      :width="'180px'"
      class="pb-2 mb-2"
  >
  </sidebar-menu>
  <phone-menu v-if="isOnMobile"></phone-menu>
  <div id="demo" :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]">
    <CStack
        direction="row"
        h="5vh"
        bg-color="primary3"
        gap="10"
        justifyContent="center"
        align-items="center"
        px="0.5vw"
    >
      <CText color="white"></CText>
      <chainInfo></chainInfo>
    </CStack>
    <router-view />
  </div>
</template>

<script>
import { computed, h, ref, onMounted, defineComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import CollapsedLogo from "@/components/CollapsedLogo.vue";
import TvLisence from "@/components/TvLisence.vue";
import WatchlistDisplay from "@/components/WatchlistDisplay.vue";
import { Analytics } from '@vercel/analytics';
import { SpeedInsights } from "@vercel/speed-insights/vue";
import chainInfo from "@/components/chainInfo.vue";

export default defineComponent({
  name: 'App',
  components: { chainInfo, CollapsedLogo, WatchlistDisplay },
  setup() {
    const collapsed = ref(false);
    const isOnMobile = ref(false);
    const componentKey = ref(0);

    const separator = h('hr', {
      style: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
        margin: '25px',
      },
    });

    const menu = computed(() => [
      {
        component: CollapsedLogo,
        hiddenOnCollapse: false,
        props: { collapsed: collapsed.value },
      },
      {
        component: WatchlistDisplay,
        hiddenOnCollapse: true,
        props: { collapsed: collapsed.value },
      },
      {
        href: '/',
        title: 'Trending',
        icon: faIcon({ icon: ['fa', 'fire'] }),
      },
      {
        href: '/pairs',
        title: 'Pairs',
        icon: faIcon({ icon: ['fab', 'ethereum'] }),
      },
      {
        href: '/',
        title: 'Method ids',
        icon: faIcon({ icon: 'fa-solid fa-code' }),
      },
      { component: separator },
      {
        header: 'Socials',
        hiddenOnCollapse: true,
      },
      {
        href: '/',
        title: 'Discord',
        icon: faIcon({ icon: ['fab', 'discord'] }),
      },
      {
        href: '/',
        title: 'Telegram',
        icon: faIcon({ icon: ['fab', 'telegram'] }),
      },
      { component: separator },
      {
        component: TvLisence,
        hiddenOnCollapse: true,
      },
    ]);

    const forceRerender = () => {
      componentKey.value += 1;
    };

    const faIcon = (props) => {
      return {
        element: h('div', [h(FontAwesomeIcon, { size: 'md', ...props })]),
      };
    };

    onMounted(() => {
      forceRerender();

      const onResize = () => {
        if (window.innerWidth <= 767) {
          isOnMobile.value = true;
          collapsed.value = true;
        } else {
          isOnMobile.value = false;
          collapsed.value = false;
        }
      };

      onResize();
      window.addEventListener('resize', onResize);
    });

    return {
      collapsed,
      isOnMobile,
      menu,
      componentKey,
    };
  },
  methods: {
    onToggleCollapse(newCollapsed) {
      this.collapsed = newCollapsed;
    },
    onItemClick(event, item) {},
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
});
</script>

<style lang="scss">
#demo {
  width: 100%;
  height: 100%;
  transition: padding-left 0.3s ease;

  // Default padding for desktop view
  padding-left: 180px;

  &.collapsed {
    padding-left: 77px;
  }

  // On mobile, override the padding
  &.onmobile {
    padding-left: 0 !important; // Use !important to ensure mobile styles override collapsed padding
  }
}

.CStack {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
