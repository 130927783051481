import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'), // Always resolves to '/'
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/TrendingView.vue'),
    },
    {
      path: '/pairs',
      name: 'pairs',
      component: () => import('../views/PairListView.vue'),
    },
    {
      path: '/pairs/:pair_address',
      name: 'pair-info',
      component: () => import('../views/PairInfoView.vue'),
      props: true,
    },
    {
      path: '/tradingview',
      name: 'tradingview',
      component: () => import('../views/TradingViewInfoView.vue'),
    },
  ],
});

export default router;
