<template>

        <div v-if="isLoading">
            <CText color="white">Loading...</CText>
        </div>

        <CStack v-else
            direction="row"
            height="4vh"
            width="auto"
            spacing="4"
            align-items="center"
            justify-content="center"

        >


                <CStack direction="row" width="auto" height="auto" spacing="1" align-items="center">
                    <font-awesome-icon :icon="['fab', 'ethereum']" style="color: white; height: 18px;"/>
                    <CText color="white" font-size="md" pt="4px">
                        $2,503.13
                    </CText>
                </CStack>


                <CStack direction="column" height="auto" spacing="8">
                    <CStack id="gas-tooltip" direction="row" width="auto" height="auto" spacing="1" align-items="center" cursor="pointer" @mouseenter="gas_hovered = true" @mouseleave="gas_hovered = false">

                        <font-awesome-icon :icon="['fa', 'gas-pump']" style="color: white; height: 18px;"/>
                        <CText color="white" font-size="md" pt="4px">
                            {{ format.toSignificantFigures((blockData.gas_price_avg)/(10**9), 3) }} gwei
                        </CText>
                    </CStack>
                    <CBox id="gas-tooltip-content" v-show="gas_hovered" cursor="pointer" @mouseenter="gas_hovered = true" @mouseleave="gas_hovered = false">
                        <CStack direction="column" p="1">

                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3">
                                <CHeading m="0" font-size="sm">ETH TRANSFER:</CHeading>
                                <CHeading m="0" font-size="sm">$2.47</CHeading>
                            </CStack>
                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3">
                                <CHeading m="0" font-size="sm">ERC20 TRANSFER:</CHeading>
                                <CHeading m="0" font-size="sm">$2.47</CHeading>
                            </CStack>
                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3">
                                <CHeading m="0" font-size="sm">DEX SWAP:</CHeading>
                                <CHeading m="0" font-size="sm">$14.47</CHeading>
                            </CStack>

                        </CStack>
                    </CBox>
                </CStack>

                <CStack direction="column" height="auto" spacing="8">
                    <CStack direction="row" width="auto" height="auto" align-items="center" cursor="pointer" @mouseenter="block_hovered = true" @mouseleave="block_hovered = false">

                        <font-awesome-icon :icon="['fa', 'cube']" style="color: white; height: 18px;"/>
                        <CStack direction="column" spacing="0">
                            <CStack direction="row" justify-content="space-between" p="0">
                                <CText color="white" m="0" line-height="14px">{{ blockData.block_number }}</CText>
                                <CText color="white" line-height="14px">{{ secondsLeft }}s</CText>
                            </CStack>
                            <CBox
                                width="8vw"
                                height="1vh"
                                bg="transparent"
                                borderRadius="12px"
                                overflow="hidden"
                                >
                                <CBox
                                    :width="progress + '%'"
                                    height="100%"
                                    borderRadius="12px"
                                    bg="#9257D2"
                                    transition="width 0.3s ease"
                                ></CBox>
                            </CBox>
                        </CStack>

                    </CStack>

                    <CBox id="gas-tooltip-content" v-show="block_hovered" cursor="pointer" @mouseenter="block_hovered = true" @mouseleave="block_hovered = false" style="margin-left: -1%;">
                        <CStack direction="column" p="1">

                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3">
                                <CHeading m="0" font-size="sm">CURRENT BLOCK</CHeading>
                                <CHeading m="0" font-size="sm">{{ blockData.block_number }}</CHeading>
                            </CStack>
                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3">
                                <CHeading m="0" font-size="sm">TX COUNT:</CHeading>
                                <CHeading m="0" font-size="sm">{{ blockData.block_tx_count}}</CHeading>
                            </CStack>
                            <!-- <CStack direction="row" justify-content="center" align-items="center" spacing="3" pt="2">

                            </CStack> -->
                            <CStack direction="column" spacing="0" pt="2">
                                <CHeading m="0" font-size="sm">GAS USAGE</CHeading>
                                <CStack direction="row" justify-content="space-between" p="0">
                                    <CHeading font-size="sm">{{ blockData.block_gas_used}}</CHeading>
                                    <CHeading font-size="sm">{{ blockData.block_gas_limit}}</CHeading>
                                </CStack>
                                <CBox
                                    width="100%"
                                    height="1vh"
                                    bg="transparent"
                                    borderRadius="12px"
                                    overflow="hidden"
                                    >
                                    <CBox
                                        :width="gasUsageWidth + '%'"
                                        height="100%"
                                        borderRadius="12px"
                                        bg="#9257D2"
                                        transition="width 0.3s ease"
                                    ></CBox>
                                </CBox>
                            </CStack>

                            <CStack direction="row" justify-content="center" align-items="center" pt="3">
                                <CHeading m="0" font-size="sm">BLOCK FEES</CHeading>
                            </CStack>
                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3">
                                <CHeading m="0" font-size="sm">BURNT FEES:</CHeading>
                                <CHeading m="0" font-size="sm">
                                    {{ formattedBurntGasFees }}
                                    <font-awesome-icon :icon="['fab', 'ethereum']" style="color: white; height: 10px;"/>
                                     ($656.50)
                                </CHeading>
                            </CStack>
                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3">
                                <CHeading m="0" font-size="sm">MINER REV:</CHeading>
                                <CHeading m="0" font-size="sm">
                                    {{ formattedMinerFees }}
                                    <font-awesome-icon :icon="['fab', 'ethereum']" style="color: white; height: 10px; mt: -2px;"/>
                                     ($226.20)
                                </CHeading>
                            </CStack>

                            <CStack direction="row" justify-content="center" align-items="center" spacing="3" pt="2">
                                <CHeading m="0" font-size="sm">GAS PRICES(gwei)</CHeading>
                            </CStack>

                            <CStack direction="row" justify-content="space-between" align-items="center" spacing="3" px="3">
                                <CStack direction="column" width="auto" align-items="center" spacing="0" color="red">
                                    <CHeading m="0" font-size="sm">BASE</CHeading>
                                    <CHeading m="0" font-size="sm">{{ format.toSignificantFigures((blockData.block_base_fee)/(10**9), 3) }}</CHeading>
                                </CStack>
                                <CStack direction="column" width="auto" align-items="center" spacing="0" color="orange">
                                    <CHeading m="0" font-size="sm">AVG</CHeading>
                                    <CHeading m="0" font-size="sm">{{ format.toSignificantFigures((blockData.gas_price_avg)/(10**9), 3) }}</CHeading>
                                </CStack>
                                <CStack direction="column" width="auto" align-items="center" spacing="0" color="green">
                                    <CHeading m="0" font-size="sm">HIGH</CHeading>
                                    <CHeading m="0" font-size="sm">{{ format.toSignificantFigures((blockData.gas_price_high)/(10**9), 3) }}</CHeading>
                                </CStack>
                            </CStack>


                        </CStack>

                    </CBox>

                </CStack>

        </CStack>



</template>

<script>
import {ref, computed, onMounted, onUnmounted, watch} from 'vue';
import * as API from "@/services/API";
import format from "@/helpers/format.js"
import {liveBlockData, subscribeToBlockEventsChannel} from '../echo.js';

export default {
    name: 'chainInfo',
    async beforeMount() {
      const channel = subscribeToBlockEventsChannel();
    },
    setup() {
        const gas_hovered = ref(false);
        const block_hovered = ref(false);

        const isLoading = ref(true);

        const blockData = ref({
            block_base_fee: 0,
            block_gas_limit: 0,
            block_gas_used: 0,
            block_number: 0,
            block_timestamp: 0,
            block_tx_count: 0,
            gas_price_avg: 0,
            gas_price_high: 0,
            gas_price_low: 0,
            gas_total_spent: 0,
            predicted_next_block_timestamp: 0,
        });

        const currentTime = ref(Math.floor(Date.now() / 1000)); // Holds the current time in UNIX timestamp (seconds)
        let targetTime = ref(currentTime.value + 12);           // Holds the target time, initially 12 seconds from now

        const progress = computed(() => {
          const startTime = blockData.value.block_timestamp; // Start time of the block
          const endTime = blockData.value.predicted_next_block_timestamp; // Predicted next block timestamp
          const current = currentTime.value;

          if (endTime > startTime) {
            return Math.min(((current - startTime) / (endTime - startTime)) * 100, 100);
          }
          return 0; // If endTime is not greater than startTime, progress is 0
        });

        const secondsLeft = computed(() => {
          const endTime = blockData.value.predicted_next_block_timestamp; // Predicted next block timestamp
          return Math.max(endTime - currentTime.value, 0); // Ensure non-negative countdown
        });

      const updateCurrentTime = () => {
            currentTime.value = Math.floor(Date.now() / 1000);  // Update current time to current UNIX timestamp (seconds)
        };

        const gasUsageWidth = computed(() => {
            return (blockData.value.block_gas_used / blockData.value.block_gas_limit) * 100;
        });

        const formattedBurntGasFees = computed(() => {
            return format.toSignificantFigures(((blockData.value.block_base_fee * blockData.value.block_gas_used) / (10**18)), 3);
        })

        const formattedMinerFees = computed(() => {
            const avgPriority = blockData.value.gas_price_avg - blockData.value.block_base_fee
            return format.toSignificantFigures(((avgPriority * blockData.value.block_gas_used) / (10**18)), 3);
        })

        const latest_block_data = async () => {
            try {
                const response = await API.apiClient.get(`/chain/latest_block`);
                blockData.value = response.data;
                isLoading.value = false;
            } catch(error) {
                console.log(error);
                isLoading.value = false;
            }
        }

        let interval;
        onMounted(() => {
            interval = setInterval(updateCurrentTime, 1000); // Update every second
            latest_block_data()
            // console.log(blockData.predicted_next_block_timestamp);
            targetTime.value = blockData.value.predicted_next_block_timestamp;
            watch(liveBlockData, (newLiveBlockData) => {
              blockData.value = {...newLiveBlockData};
            }, { deep: true });
        });

        onUnmounted(() => {
            clearInterval(interval);  // Cleanup the timer on unmount
        });

        return {
            format,
            formattedBurntGasFees,
            formattedMinerFees,
            blockData,
            gas_hovered,
            block_hovered,
            progress,
            currentTime,
            targetTime,
            secondsLeft,
            gasUsageWidth,
            isLoading
        };
    }

};
</script>

<style scoped>


/* Tooltip text */
#gas-tooltip-content {
    width: auto;
    background-color: rgb(60, 60, 60);
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    margin-left: -2.5%;
    border-radius: 6px;
    position: absolute;
    z-index: 100;
}

.gas-tooltip-content:after {
    content:'';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 25px solid #6A0136;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
}


</style>