class format {
    static shortenAddress(value) {
        return `${value.slice(0, 6)}...${value.slice(-4)}`;
    }

    static timeAgo(value) {
        const past = new Date(value * 1000);
        const now = new Date();
        const diff = Math.abs(now - past); 

        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); 

        const remainingDays = days % 30;
        const remainingHours = hours % 24;
        const remainingMinutes = minutes % 60;
        const remainingSeconds = seconds % 60;

        const timeUnits = [{ value: months, label: 'm' },{ value: remainingDays, label: 'd' },{ value: remainingHours, label: 'h' },{ value: remainingMinutes, label: 'm' },{ value: remainingSeconds, label: 's' },];

        const largestUnits = timeUnits.filter(unit => unit.value > 0).slice(0, 2);

        const result = largestUnits
            .map(unit => `${unit.value}${unit.label}`)
            .join(' ');

        return `${result} ago` || 'just now'; // If no significant time has passed
    }

    static formatNumber(num) {
        if (num === 0) return "0"; // Return 0 if the number is 0
        if(isNaN(typeof num)) return '0';

        if (num < 1000) return num.toString();
    
        const units = ["K", "M", "B", "T"]; // add more if needed
        let unitIndex = -1;
    
        while (Math.abs(num) >= 1000 && unitIndex < units.length - 1) {
            num /= 1000;
            unitIndex++;
        }

        const formatted = num.toPrecision(3);
    
        return formatted.replace(/\.0+$/, "") + units[unitIndex];
    }

    static toSignificantFigures(num, figures) {
        if (num === 0) return 0;
        
        const magnitude = Math.floor(Math.log10(Math.abs(num)));
        const factor = Math.pow(10, figures - magnitude - 1);
        
        return Math.round(num * factor) / factor;
    }

    static toFixed(num, decimalPlaces) {
        if (typeof num !== 'number') {
            console.warn('Input to toFixed is not a number:', num);
            return '0.00';
        }
        return num.toFixed(decimalPlaces);
    }
}

export default format;