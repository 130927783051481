// theme.js
import { extendTheme, theme as base} from "@chakra-ui/vue-next";
import './assets/css/josefin.css'
import {tableTheme} from "../themes/tableAnatomy.js";
import { buttonTheme } from "../themes/buttonAnatomy.js";

export const extendedTheme = extendTheme({
    components: {
        Table: tableTheme,
        Button: buttonTheme
    },
    styles: {
        global: {
            "@font-face": [
                {
                    fontFamily: 'Josefin',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontDisplay: 'swap',
                    src: `url('/fonts/JosefinSans-SemiBold.ttf') format('truetype')`
                },
                {
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 900,
                    fontDisplay: 'swap',
                    src: `url('/fonts/Lato-Regular.ttf') format('truetype')`
                },
                {

                    fontFamily: 'LatoBold',
                    fontStyle: 'normal',
                    fontWeight: 900,
                    fontDisplay: 'swap',
                    src: `url('/fonts/Lato-Bold.ttf') format('truetype')`
                },
                {
                    fontFamily: 'Manrope',
                    fontWeight: 900,
                    src: `url('/fonts/Manrope-Regular.ttf') format('truetype')`
                },
                {
                    fontFamily: 'ManropeLight',
                    src: `url('/fonts/Manrope-ExtraLight.ttf') format('truetype')`
                },
                {
                    fontFamily: 'Poppins',
                    src: `url('/fonts/Poppins-SemiBold.woff2') format('woff2')`

                },
            ],
            "chakra-text": {
              marginBottom: 0,
            },
            body: {
                bg: '#131b3c',
            }

        },


    },
    colors: {
        brand: {
            100: "#E4D1F2",
            200: "#C9A3E5",
            300: "#AF76D8",
            400: "#9448CC",
            500: "#9257D2",
            600: "#7D3CB5",
            700: "#672F8F",
            800: "#512369",
            900: "#3B1843"
        },
        brand2: {
            100: "#D6E8FF",  // Lightest teal
            200: "#ADD2FF",  // Lighter teal
            300: "#85BBFF",  // Light teal
            400: "#5CA5FF",  // Medium teal
            500: "#4E95FF",  // Base teal (main color)
            600: "#4176D1",  // Darker teal
            700: "#3458A3",  // Even darker teal
            800: "#283B75",  // Dark teal
            900: "#1B1E47"   // Darkest teal
        },
        bgWhite: '#0F1024',
        primary: '#4E95FF',
        tagSpecial: '#091d36',
        danger: '#0F1024',
        secondary: '#FFFFFF',
        tertiary: '#000000',
        error: '#b34d4d',
        bgBody: '#ffffff',
        primary1: '#15296c',
        primary2: '#161953',
        primary3: '#131B3D',
        primary4: '#0F1024',
        baseBg: '#15296c',
        secondary1: '#FFFFFF',
        secondary2: '#adadad',
        secondary3: '#5e5e5e',
        secondary4: '#1f1f1f',
        accent1: '#1B76FF',
        accent2: '#4E95FF',
        accent3: '#7B92F2',
        accent4: '#9257D2',
    },
    fonts: {
        body: `'Poppins', sans-serif`, // Primary body font
        heading: `'Lato', sans-serif`, // Heading font with correct family
        sizeBase: '0.9rem', // Base font size
        lineHeightBase: 1.6, // Base line height for readability
        displayFontSizes: {
            1: '1.1rem', // Smaller display size
            2: '2.1rem', // Larger display size
        },
        displayFontWeight: 300, // Default weight for display fonts
        // Additional font weights for headings
        headingWeights: {
            normal: 400, // Normal heading weight
            bold: 700, // Bold heading weight
            extraBold: 900, // Extra bold for emphasis
        },
    },
    fontSizes: {
        xxs: '0.50rem', // Extra extra small
        xs: '0.65rem', // Extra small
        sm: '0.75rem', // Small
        md: '1rem', // Medium
        lg: '1.125rem', // Large
        xl: '1.25rem', // Extra large
        '2xl': '1.5rem', // 2X extra large
        '3xl': '1.875rem', // 3X extra large
        '4xl': '2.25rem', // 4X extra large
        '5xl': '3rem', // 5X extra large
        '6xl': '4rem', // 6X extra large
        '7xl': '5rem', // New: 7X extra large
    },
    // Optional: Define additional typography properties
    letterSpacings: {
        normal: '0', // Default letter spacing
        wide: '0.05em', // Wider letter spacing
        wider: '0.1em', // Even wider
    },
    textStyles: {
        body: {
            fontSize: 'sizeBase',
            lineHeight: 'lineHeightBase',
            fontWeight: 400, // Normal font weight for body
        },
        heading1: {
            fontSize: '4xl', // Use '4xl' for H1
            fontWeight: 'headingWeights.bold', // Bold font weight
            lineHeight: 1.2, // Tighter line height for headings
        },
        heading2: {
            fontSize: '3xl', // Use '3xl' for H2
            fontWeight: 'headingWeights.normal', // Normal font weight
            lineHeight: 1.3, // Tighter line height for H2
        },
        // Add more text styles as needed
    },
    space: {
        xs: '0.25rem',
        sm: '0.8rem',
        md: '1rem',
        lg: '1.5rem',
        xl: '2rem',
    },
    sizes: {
        container: {
            sm: '100%',
            md: '720px',
            lg: '960px',
            xl: '1140px',
        },
    },
    breakpoints: {
        sm: "320px",
        md: "768px",
        lg: "960px",
        xl: "1200px",
    },

});